import * as React from "react"
import * as SuccessStyles from "./success.module.css"
import Layout from "../components/Layout/Layout"
import { graphql, Link } from "gatsby"
import TxtImg from "../components/TxtImg/TxtImg"
import { getImage } from "gatsby-plugin-image"

export default function Success({ data }) {
    const image = getImage(data.image)
    return (
      <Layout>
        <main className={SuccessStyles.frame}>
            <TxtImg split="half" imgFirst={true} imgSrc={image} imgAlt="">
                <h1><span className={SuccessStyles.highlight}>Thank you</span></h1>
                <p>Your message has been sent successfully and I'll do my best to respond as soon as possible.</p>
                <Link className={SuccessStyles.btn} to="/">Return home</Link>
            </TxtImg>
        </main>
      </Layout>
    )
}

export const query = graphql`
  query {
    image: file(relativePath: {eq: "pv-porsche2.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 900)
      }
    }
  }
`